<template>
    <div id="pageTable">
        <v-overlay :value="loadingLottie"  :z-index="999" >
            <div class="align-center justify-center text-center">
                <v-progress-circular
                    indeterminate
                    :size="100"
                    :width="7"
                    color="#2DB9D1"
                >
                    <v-img
                        src="/static/icon/favicon.ico"
                        :width="60"
                    ></v-img>
                </v-progress-circular>
                <p class="mt-5">{{fraseLoading}}</p>
            </div>
        </v-overlay>    
        <v-container xl fluid>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" class="mx-0 px-0">
                    <v-breadcrumbs :items="itemsLinks">
                        <template v-slot:divider>
                            <v-icon>chevron_right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Detalle acumulado'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        :showAdvancedFilters="false"
                        :perPage="[10,25,50,100]"
                        :isSelectable="true"
                        :unselected="unselected_general"
                        @selectAllEvent="selectAll"
                        :valCheck="'id'"
                    >
                        <template slot="actionButtonsLeft">
                            <v-tooltip bottom  >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-on="on"
                                        :disabled="!masivo"
                                        fab
                                        color="#004BAF"
                                        class="elevation-0 mr-3 btn-acciones"
                                        @click="timbrar(null, 'empleados')"
                                    >
                                        <v-icon color="#FFFFFF">mdi-bell-ring-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Timbrar seleccionados</span>
                            </v-tooltip>
                        </template>
                        <!-- <template slot="actionButtonsLeft">
                            <v-tooltip bottom  >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-on="on"
                                        fab
                                        color="#FFFFFF"
                                        class="elevation-0 btn-acciones"
                                        @click="desacumular(item, 'empleados')"
                                    >
                                        <v-icon class="outlined_v_icon" color="#101C5A">downloading</v-icon>
                                    </v-btn>
                                </template>
                                <span>Desacumular seleccionados</span>
                            </v-tooltip>
                        </template> -->
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td style="width:115px !important; ">
                                    <div >
                                        <v-checkbox class="mb-5" primary hide-details :value="item.id" v-model="selected_general" ></v-checkbox>
                                    </div>
                                </td> 
                                <td><div>{{item.nombre_completo}}</div></td>
                                <td><div>{{formatoMoneda(item.total_percepciones)}}</div></td>
                                <td><div>{{formatoMoneda(item.total_deducciones)}}</div></td>
                                <td><div>{{formatoMoneda(item.neto_pagar)}}</div></td>
                                <td>
                                    <div class="tblOpciones">
                                        <v-tooltip bottom v-if="item.errores !== null && !item.cancelado">
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="timbrar(item, 'empleado')">
                                                    <v-icon class="tamIconoBoton iconoDelgadoBoton">mdi-bell-ring-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Timbrar</span>
                                        </v-tooltip>
                                        <!-- <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="desacumular(item, 'empleado')">
                                                    <v-icon class="outlined_v_icon tamIconoBoton iconoDelgadoBoton">downloading</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Desacumular</span>
                                        </v-tooltip> -->
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import Datatable from '@/components/datatable/Datatable.vue';
import Notify from '@/plugins/notify';
import Lottie from 'vue-lottie';
import animationData from '@/animations/cliker.json';
import LoadingCliker from '@/components/LoadingCliker';
import apiCalculoNomina from '@/api/nominas/calculoNomina';

export default {
    components: {
        'data-table': Datatable,
        'lottie': Lottie,
        'loading-cliker':LoadingCliker,
    },
    data() {
        return {
            url         : "nominas-timbres/detalle",
            columns     : [
                {
                    label: 'Empleado',
                    name: 'nombre_completo',
                    filterable: false
                },
                {
                    label: 'Total percepciones',
                    name: 'total_percepciones',
                    filterable: false
                },
                {
                    label: 'Total deducciones',
                    name: 'total_deducciones',
                    filterable: false
                },
                {
                    label: 'Neto a pagar',
                    name: 'neto_pagar',
                    filterable: false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            itemsLinks      : [
                {
                    text: 'Nóminas acumuladas',
                    disabled: false,
                    to: '/calculoNominaAcumulado',
                },
                {
                    text: 'Detalle del periodo',
                    disabled: true,
                    to: '/detalleAcumulado',
                },
            ],
            filters     : {
                activo    :true,
                paginate  :true,
                order     :"asc",
                nomina_id : null
            },
            userData                : this.$session.get('usuario'),
            accion                  : null,
            tituloModal             : null,
            dialog                  : false,
            isLoading               : false,
            isSaving                : false,
            imagen                  : "",
            timbreNomina            : null,
            checkAll                : false,
            selected_general        : [],
            unselected_general      : [],
            masivo                  : false,
            defaultOptions          : { animationData: animationData },
            loadingLottie           : false,
            fraseLoading            : 'Timbrando',
        }
    },
    watch:{
        selected_general:function(value) {
            if(value.length > 0 ){
                this.masivo =  true;
            }
            else{
                this.masivo =  false;
            }
        },
    },
    methods: {
        abrirModal(accion, data = null) {
            this.accion     = accion;
            this.isLoading  = true;

            this.isLoading  = false;
            //setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.resetValues();
        },
        resetValues() {
           
            this.$nextTick(() => {
                this.$refs.form.reset();
            });
        },
        guardar() {
            
        },
        eliminar(data) {
            
        },
        /**
         * @method formatoMoneda Este método sirve para formatear el valor a formato moneda en la tabla principal.
         * @description Este método sirve para formatear el valor a formato moneda en la tabla principal.
         */
        formatoMoneda(value){
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(value)
        },
        /**
         * @method selectAll Este método se realiza al dar clic en el check que esta en el header de la tabla principal.
         * @description Este método se realiza al dar clic en el check que esta en el header de la tabla principal. Se selecciones todos los check o se deseleccionan.
         * Se ejecuta con una emit del componente tabla.
         * @param {boolean} isSelected variable para saber si check se esta seleccionado o no.
         * @param {object} items variable donde vienen todos los items de los empledos.
         */
        selectAll(isSelected, items) {
            this.checkAll = isSelected;
            this.selected_general = items;

            if(!isSelected){
                this.unselected_general = [];
            }
        },
        /**
         * @method desacumular Este método sirve para desacumular
         * @description Este método sirve para desacumular un empleado.
         * @param {object} data variable donde vienen todos los datos del empleado.
         */
        async desacumular(data, accion = null){
            let param = {};
            if(accion == 'empleado'){
                param = { 
                    nomina_id:this.timbreNomina.id, 
                    empleados:[data.id],
                    todos: false
                };
            }
            else if(accion == 'empleados'){
                if(this.selected_general.length == 0){
                    Notify.ErrorToast("Seleccionar al menos un registro");
                    return;
                }

                param = { 
                    nomina_id:this.timbreNomina.id, 
                    empleados:this.selected_general,
                    todos: false
                };
            }
            else{
                Notify.ErrorToast("no se eligio una accion");
                return;
            }

            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas realizar el proceso de desacumulación?",
                "Desacumular",
                () => {
                    apiCalculoNomina.desacumular(param).then(response => {
                        Notify.Success("Operación exitosa", "La desacumulación se ha realizado satisfactoriamente.");
                        this.$refs.tabla.getData();
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )

        },
        timbrar(data, accion){

            let mensaje = '';
            let param = { nominas: []}

            if(accion === 'empleado'){
                mensaje = "¿Estás seguro que deseas timbrar al empleado "+data.nombre_completo+"?";
                param = { nominas: [data.id]}
            }
            else{
                mensaje = "¿Estás seguro que deseas timbrar los empleados seleccionados?. No se enviaran a timbrar los empleados con estatus 'Correcto'"
                param = { nominas: this.selected_general}
            }

            console.log(param)

            /* Notify.Alert(
                "ADVERTENCIA",
                mensaje,
                "Timbrar",
                () => {
                    this.fraseLoading = "Timbrando";
                    this.loadingLottie = true;
                    apiCalculoNomina.timbraErrores(param).then(response => {
                        this.loadingLottie=false; 
                        this.$refs.tabla.unselectAll();
                        this.selected_general=[];
                        if(response.status === 202){
                            // mensaje para  response.data.message
                            if(typeof response.data.message === 'string'){
                                Notify.ErrorToast(response.data.message +" Favor de verificar el detalle");
                            }else{
                                let error = Object.values(response.data.message);
                                let leyenda = ``;

                                for (var i = 0; i < error.length; i++) {
                                    leyenda+= `* ` + error[i] + `\n`;
                                }
                                leyenda += " Favor de verificar el detalle";
                                Notify.ErrorToast(leyenda);
                            }

                        }
                        if(response.status === 200){
                            Notify.Success("Timbrado", "El timbrado se realizo correctamente.");
                        }
                        this.detalleTimbre();
                    })
                    .catch(err => {
                        this.loadingLottie=false; 
                        this.$refs.tabla.unselectAll();
                        this.selected_general=[];
                        this.detalleTimbre();
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            ) */
        },
        detalleTimbre(){
            this.$session.remove("detalleTimbre");
            this.$session.set('detalleTimbre',this.timbreNomina);
            this.$router.push('/detalleTimbre');
        },
    },
    beforeCreate(){
        if(!this.$session.get('detalleAcumulado')){
            this.$router.push('/dashboard');
        }
    },
    created() {
        this.timbreNomina = this.$session.get('detalleAcumulado');
        this.filters.nomina_id = this.timbreNomina.id;
    },
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .btn-acciones:hover{
        color: #FFFFFF;    
    }
</style>
